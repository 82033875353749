/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'rotate_right': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M522-80v-82q34-5 66.5-18t61.5-34l56 58q-42 32-88 51.5T522-80zm-80 0Q304-98 213-199.5T122-438q0-75 28.5-140.5t77-114q48.5-48.5 114-77T482-798h6l-62-62 56-58 160 160-160 160-56-56 64-64h-8q-117 0-198.5 81.5T202-438q0 104 68 182.5T442-162v82zm322-134l-58-56q21-29 34-61.5t18-66.5h82q-5 50-24.5 96T764-214zm76-264h-82q-5-34-18-66.5T706-606l58-56q32 39 51 86t25 98z"/>'
  }
})
