import { ActaAppDetails } from '@/models/app';
import styles from '@/styles/variables.module.scss';
const MB_IN_BYTES = 1024 * 1024;
const GB_IN_BYTES = 1024 * MB_IN_BYTES;

export const Config = {
  PERMANENT_DELETE_VERIFICATION_TEXT: 'PERMANENT DELETE',
  EMPTY_TRASH_VERIFICATION_TEXT: 'EMPTY TRASH',
  NAME_FORMAT_MAX_NAME_LENGTH:150,
  SINGLE_ITEM_ARRAY_LENGTH :1,
  APP_LIST: [] as Array<ActaAppDetails>,
  ACTIVE_SECTION_KEY: 'mylibrary',
  CONTACT_EMAIL_TO_BIND: 'mailto:support@actapublica.se',
  MAX_UPLOAD_FILE_SIZE_IN_BYTES: 500 * MB_IN_BYTES, 
  TARGET_BREADCRUMB_OFFSET: 2,
  DOWNLOAD_LISTING_OBJECT_URL: 'objects',
  DOWNLOAD_SHARED_OBJECT_URL: 'shares',
  FILE_CHUNK_SIZE_IN_BYTES: 1 * MB_IN_BYTES, // 1MB
  FILE_UPLOAD_RETRY_LIMIT: 3,
  FILE_UPLOAD_RETRY_DELAY: 1000 * 15, // 15 Secs,
  CONSTANTS: {
    FILE: 'file',
    FOLDER: 'folder'
  },
  CSS_VARIABLES: {
    MENU_BG_COLOR: styles.menuBg,
    MENU_TEXT_COLOR: styles.menuText,
    MENU_ACTIVE_TEXT_COLOR: styles.menuActiveText
  },
  PRIMARY_THEME_COLOR: '1890ff',
  APP_MAIN_TITLE: 'MyLibrary - Acta Publica',
};

