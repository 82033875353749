<template>
  <div :class="{'has-logo': showLogo}">
    <div v-if="showLogo" class="sidebar-logo-container" :class="{collapse: isCollapse}">
      <transition name="sidebarLogoFade">
        <router-link v-if="isCollapse" key="collapse" class="sidebar-logo-link" to="/">
          <img src="@/assets/images/acta-icon.svg" class="sidebar-logo">
        </router-link>
        <router-link v-else key="expand" class="sidebar-logo-link" to="/">
          <img src="@/assets/images/acta-icon.svg" class="sidebar-logo">
          <h1 class="sidebar-title">
            Acta Publica
          </h1>
        </router-link>
      </transition>
    </div>
    <new-object :open-dialog-prop="dialogVisible" :type-prop="newObjectType" @close-new-object-dialog="closeDialog()" />
    <el-scrollbar wrap-class="scrollbar-wrapper ">
      <el-menu
        :collapse="isCollapse" :default-active="defaultActive" :active-text-color="menuActiveTextColor"
        :unique-opened="false" :collapse-transition="false" :router="true" menu-trigger="hover" mode="vertical"
      >
        <el-menu-item index="/browse" :router="true">
          <svg-icon class="icon-router" name="documentation" />
          <span slot="title">{{ $t('route.browse') }}</span>
        </el-menu-item>
        <el-menu-item index="/shared">
          <svg-icon class="icon-router" name="share" />
          <span slot="title">{{ $t('route.shared') }}</span>
        </el-menu-item>
        <el-submenu :disabled="$route.name !== 'browse'" index="/upload">
          <template slot="title">
            <svg-icon class="icon-router" name="add_circle" />
            <span slot="title">{{ $t('route.new') }}</span>
          </template>
          <el-menu-item :disabled="$route.name !== 'browse'" @click="openNewObjectDialog('file')">
            <svg-icon class="icon-router" name="cloud_upload" />
            <span slot="title">{{ $t('route.upload_file') }}</span>
          </el-menu-item>
          <el-menu-item :disabled="$route.name !== 'browse'" @click="openNewObjectDialog('folder')">
            <svg-icon class="icon-router" index="/upload" name="cloud_upload" />
            <span slot="title">{{ $t('route.create_folder') }}</span>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/trash">
          <svg-icon class="icon-router" name="delete" />
          <span slot="title">{{ $t('route.trash') }}</span>
        </el-menu-item>
      </el-menu>
    </el-scrollbar>

  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import { SettingsModule } from '@/store/modules/settings';
import NewObject from '@/components/objects/new-object.vue';
import EventBus from '@/app/event-bus';
import { Config } from '@/app/config';
import { EmitterKey, ObjectType } from '@/models/app';

@Component({
  name: 'AppSidebar',
  components: { NewObject },
})
export default class extends Vue {
  dialogVisible = false;
  newObjectType: 'file' | 'folder' = 'file';

  mounted() {
    EventBus.$on(EmitterKey.PROMPT_CREATE_NEW, this.createPromptListener);
  }

  beforeDestroy() {
    EventBus.$off(EmitterKey.PROMPT_CREATE_NEW, this.createPromptListener);
  }

  createPromptListener = (type:ObjectType) => {
    this.openNewObjectDialog(type);
  };

  get sidebar() {
    return AppModule.sidebar;
  }

  get showLogo() {
    return SettingsModule.showSidebarLogo;
  }

  openNewObjectDialog(type: 'file' | 'folder') {
    this.newObjectType = type;
    this.dialogVisible = true;
  }

  get menuActiveTextColor() {
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme;
    } else {
      return Config.CSS_VARIABLES.MENU_ACTIVE_TEXT_COLOR;
    }
  }
  get logoutMenuTextColor(){
    return Config.CSS_VARIABLES.MENU_TEXT_COLOR;
  }


  get isCollapse() {
    return !this.sidebar.opened;
  }

  get defaultActive() {
    return '/' + this.$route.path.split('/').filter((x) => x.trim())[0];
  }

  closeDialog() {
    this.dialogVisible = false;
  }
}
</script>

<style lang="scss" scoped>
.sidebar-container {
  box-shadow: 0px 1px 1px 1px #c4c4c459;

  .horizontal-collapse-transition {
    transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;
  }

  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-scrollbar::v-deep {
    .el-scrollbar__view {
      position: relative;
    }

    .el-scrollbar__bar {
      &.is-vertical {
        right: 0px;
      }

      &.is-horizontal {
        display: none;
      }
    }
  }
}

.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to,
.sidebarLogoFade-leave {
  .sidebar-title {
    display: none;
  }
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 100px;
  text-align: center;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      margin: 0;
      color: #053678;
      font-weight: 600;
      font-size: 18px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      text-transform: uppercase;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}

.icon-router {
  margin-right: 12px;
}

.el-scrollbar {
  height: 100%;
  background: #ffffff;
}

.has-logo {
  .el-scrollbar {
    height: calc(100% - 160px);
  }
}

.el-menu {
  border: none;
  height: 100%;
  width: 100% !important;
}
</style>
