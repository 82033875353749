import i18n from '@/lang';
import EventBus from '@/app/event-bus';
import { createFolderObject, uploadFileChunk } from './object-api';
import { Config } from '@/app/config';
import { UploadChunkItem, UploadQueueItem, UploadStatus } from '@/models/upload.models';
import { promiseDelay } from '@/utils/common';
import { EmitterKey, openFolderEventProp } from '@/models/app';

class ObjectService {
    public activeFolderId = 0;
    public objectNameRegex = /^[^<>:"\\/|?*]+$/;
    public reTryEnabledHttpStatus = [408,400,425,429];
    public uploadQueueList: UploadQueueItem[] = [];

    public validateName = (rule: any, value: any, callback: any) => {
        if (value === '') {
            callback(new Error(i18n.t('formValidation.enter_name').toString()));
        } else {
            if (!this.objectNameRegex.test(value)) {
                callback(new Error(i18n.t('formValidation.enter_valid_name').toString()));
            }
            callback();
        }
    }

    private confirmExitMessage(event: any) {
        event.returnValue = i18n.t('uploadPage.exit_confirmation').toString();
    }

    private confirmExitMessageEnable() {
        window.addEventListener('beforeunload', this.confirmExitMessage);
    }
    private confirmExitMessageDisable() {
        window.removeEventListener('beforeunload', this.confirmExitMessage);
    }

    public validateObjectName = (name: any) => {
        if (!this.objectNameRegex.test(name)) {
            return false;
        }
        return true;
    }

    private scheduleNextUpload() {
        //We only upload one file at a time,
        //This method must trigger when new item is added or existing upload job is finished
        const anyIPTask = this.uploadQueueList.find(queueItem => queueItem.status === UploadStatus.IN_PROGRESS);
        if (!anyIPTask) {
            //no tasks are inprogress
            const awaitingTask = this.uploadQueueList.find(queueItem => queueItem.status === UploadStatus.AWAITING);
            if (awaitingTask) {
                this.fileUpload(awaitingTask);
                this.confirmExitMessageEnable();
            } else {
                this.confirmExitMessageDisable();
            }
        }
    }

    async fileUpload(item: UploadQueueItem) {

        let errorMessage = '';
        let chunkIndexStart = 0;

        if (item.status === UploadStatus.RESUME) {
            chunkIndexStart = item.chunkList.findIndex(ch => ch.chunkStatus === UploadStatus.PAUSE);
            chunkIndexStart = Math.max(chunkIndexStart, 0);// Make -1, fallback to 0.
        } else {
            item.status = UploadStatus.IN_PROGRESS;
        }
        EventBus.$emit(EmitterKey.FILE_UPLOAD_STARTED,item);

        chunkUploadLoop:
        for (let index = chunkIndexStart; index < item.chunkList.length; index++) {
            const chunk = item.chunkList[index];
            let reUpload = true;
            if (chunk.chunkStatus === UploadStatus.RESUME) {
                chunk.chunkStatus = UploadStatus.RESUME;
            } else {
                chunk.chunkStatus = UploadStatus.IN_PROGRESS;
            }
            while (reUpload) {
                if ((item.status as UploadStatus) === UploadStatus.PAUSE) {
                    chunk.chunkStatus = UploadStatus.PAUSE;
                }
                if ((item.status as UploadStatus) === UploadStatus.CANCELED) {
                    const cancelledItemIndex = this.uploadQueueList.findIndex(queueItem => queueItem.fileId === item.fileId);
                    this.uploadQueueList.splice(cancelledItemIndex, 1);
                }
                if ((item.status as UploadStatus) === UploadStatus.PAUSE || (item.status as UploadStatus) === UploadStatus.CANCELED) {
                    return;
                }
                EventBus.$emit(EmitterKey.FILE_UPLOAD_PROGRESS,item);
                const formData = this.createUploadFormData(item, chunk, index);
                const result = await uploadFileChunk(formData);
                if (result.success) {
                    chunk.chunkStatus = UploadStatus.DONE;
                    reUpload = false;
                } else {
                    const hasReTryReasons = this.reTryEnabledHttpStatus.includes(result?.res?.status);
                    const isServerError = result?.res?.status === 500;
                    chunk.chunkStatus = UploadStatus.FAILED;
                    reUpload = chunk.retries < Config.FILE_UPLOAD_RETRY_LIMIT && (isServerError||hasReTryReasons);
                    chunk.retries++;
                    if (reUpload) {
                        await promiseDelay(Config.FILE_UPLOAD_RETRY_DELAY);
                        errorMessage = result.message || '';
                    }
                }
            }
            if (chunk.chunkStatus === UploadStatus.FAILED) {
                break chunkUploadLoop;
            }
            if ((item.status as UploadStatus) === UploadStatus.CANCELED) {
                const cancelledItemIndex = this.uploadQueueList.findIndex(queueItem => queueItem.fileId === item.fileId);
                this.uploadQueueList.splice(cancelledItemIndex, 1);
            }
            if ((item.status as UploadStatus) === UploadStatus.PAUSE || (item.status as UploadStatus) === UploadStatus.CANCELED) {
                EventBus.$emit(EmitterKey.FILE_UPLOAD_INTERRUPTED, item);
                return;
            }
        }
        item.status = item.chunkList.every(ch => ch.chunkStatus === UploadStatus.DONE) ? UploadStatus.DONE : UploadStatus.FAILED;
        item.chunkList.forEach(ch => ch.chunkItem = new Blob([]));
        const broadcastPayload = { name: item.name, errorMessage: errorMessage, status:item.status};

        EventBus.$emit(EmitterKey.FILE_UPLOAD_COMPLETED, broadcastPayload);
        if (item.status === UploadStatus.DONE) {
            this.refreshFolder();
        }
        this.scheduleNextUpload();
    }

    createUploadFormData(item: UploadQueueItem, chunk: UploadChunkItem, index: number) {
        const currentItem = (index + 1);
        const formData = new FormData();
        formData.append('type', 'file');
        formData.append('name', item.name);
        formData.append('file_size', item.size + '');
        formData.append('mime_type', item.mime);
        formData.append('file', chunk.chunkItem);
        formData.append('parent_folder', item.parentFolderId + '');
        formData.append('file_unique_id', chunk.uuid);
        formData.append('is_last', currentItem === item.chunkList.length ? '1' : '0');
        formData.append('total_chunks', item.chunkList.length + '');
        formData.append('current_chunk', currentItem + '');
        return formData;
    }

    uuidGenerator() {
        const global = (window as any);
        if (!global._uuidCounter) {
            global._uuidCounter = Math.round(Math.random() * 100000);
        }
        global._uuidCounter++;
        return global._uuidCounter;
    }

    private processFileForUploading(file: File, parentFolderId: number): UploadQueueItem {
        const FILE_NAME_UUID_SANITIZE_REG_EXP = /[^A-Za-z0-9\s]/gm;
        const MATCH_SPACE_REG_EXP = /\s+/gm;

        const name = file.name;
        const size = file.size;
        const mime = file.type;
        const fileId = this.uuidGenerator();
        const chunks: Blob[] = [];
        // NOTE: The file name is included in the UID to facilitate chunk identification during server debugging
        const nameExtForUUid = (name || '').replace(FILE_NAME_UUID_SANITIZE_REG_EXP, '').replace(MATCH_SPACE_REG_EXP, '_');
        const fileUuid = `${this.uuidGenerator()}_${nameExtForUUid}`;
        let chunkTrimStart = 0;
        while (chunkTrimStart < size) {
            const sliceEnd = chunkTrimStart + Config.FILE_CHUNK_SIZE_IN_BYTES;
            const piece = file.slice(chunkTrimStart, sliceEnd);
            chunks.push(piece);
            chunkTrimStart = sliceEnd;
        }
        const chunkList: UploadChunkItem[] = chunks.map((ch) => ({
            chunkItem: ch,
            size: ch.size,
            chunkStatus: UploadStatus.AWAITING,
            retries: 0,
            uuid: fileUuid,
        }));

        return {
            fileId,
            name,
            size,
            mime,
            chunkList,
            parentFolderId,
            status: UploadStatus.AWAITING,
        };
    }

    public createFile(file: File, parentFolderId?: number) {
        if (parentFolderId === undefined) {
            parentFolderId = this.activeFolderId;
        }
        if (!file) {
            return;
        }
        const uploadState = this.processFileForUploading(file, parentFolderId);
        this.uploadQueueList.push(uploadState);
        this.scheduleNextUpload();
    }

    public createFolder(name: string, parentFolderId?: number) {
        if (parentFolderId === undefined) {
            parentFolderId = this.activeFolderId;
        }
        const formData = new FormData();
        formData.append('name', name);
        formData.append('type', 'folder');
        formData.append('parent_folder', parentFolderId + '');
        return createFolderObject(formData);

    }

    public refreshFolder(reSelect = false) {
        const payload: openFolderEventProp = { id: this.activeFolderId, reSelect };
        EventBus.$emit('openFolder', payload);
    }

    public cancelUpload(id: string) {
        const item = this.uploadQueueList.find(queueItem => queueItem.fileId === id);
        if (item) {
            item.status = UploadStatus.CANCELED;
            item.chunkList.forEach(ch => ch.chunkStatus = UploadStatus.CANCELED);
            EventBus.$emit(EmitterKey.FILE_UPLOAD_ACTION,item);
        }
    }

    public pauseUpload(id: string) {
        const item = this.uploadQueueList.find(queueItem => queueItem.fileId === id);
        if (item) {
            item.status = UploadStatus.PAUSE;
            EventBus.$emit(EmitterKey.FILE_UPLOAD_ACTION,item);
        }
    }

    public resumeUpload(id: string) {
        const item = this.uploadQueueList.find(queueItem => queueItem.fileId === id);
        if (item) {
            item.status = UploadStatus.RESUME;
            EventBus.$emit(EmitterKey.FILE_UPLOAD_ACTION,item);
            this.fileUpload(item);
        }
    }
}

const objectService = new ObjectService();
export default objectService;