/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fit_content': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M800-600v-120H680v-80h120q33 0 56.5 23.5T880-720v120h-80zm-720 0v-120q0-33 23.5-56.5T160-800h120v80H160v120H80zm600 440v-80h120v-120h80v120q0 33-23.5 56.5T800-160H680zm-520 0q-33 0-56.5-23.5T80-240v-120h80v120h120v80H160zm80-160v-320h480v320H240zm80-80h320v-160H320v160zm0 0v-160 160z"/>'
  }
})
