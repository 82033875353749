import { IFile, IFolder } from './file';
export interface BreadcrumbHandler {
    redirect: any
    path: string
    compiledPath: string
    display: String
    breadcrumbType: 'Route' | 'FilePath'
}
export interface MenuDetails {
    menuName: DefinedMenuName
    options: MenuOption[]
    handler: (response: MenuOption, payload: IFile | IFolder) => void
}
export interface MenuOption {
    i18nLabel: string
    key: MenuKey
    icon: string
}

export interface openFolderEventProp {
    id: number
    reSelect: boolean
}
export interface ActaAppDetails {
    name: string
    link: string
    identification_key: string
}

export enum MediaType {
    AUDIO = 'AUDIO',
    VIDEO = 'VIDEO',
    UNSUPPORTED = 'UNSUPPORTED',
}

export enum FilePreviewType {
    PDF = 'PDF',
    MEDIA = 'MEDIA',
    IMAGE = 'IMAGE',
    UNSET = 'UNSET',
}
export enum EmitterKey {
    PREVIEW_LOADER = 'PREVIEW_LOADER',
    OPEN_FILE_PREVIEW = 'OPEN_FILE_PREVIEW',
    CLOSE_FILE_PREVIEW = 'CLOSE_FILE_PREVIEW',
    PROMPT_CREATE_NEW ='PROMPT_CREATE_NEW',
    FILE_UPLOAD_STARTED= 'FILE_UPLOAD_STARTED',
    FILE_UPLOAD_PROGRESS= 'FILE_UPLOAD_PROGRESS',
    FILE_UPLOAD_INTERRUPTED= 'FILE_UPLOAD_INTERRUPTED',
    FILE_UPLOAD_COMPLETED= 'FILE_UPLOAD_COMPLETED',
    FILE_UPLOAD_ACTION= 'FILE_UPLOAD_ACTION',
}
export enum MenuKey {
    UPLOAD_FILE = 'UPLOAD_FILE',
    CREATE_FOLDER = 'CREATE_FOLDER',
    OPEN = 'OPEN',
    DOWNLOAD = 'DOWNLOAD',
    RENAME = 'RENAME',
    DELETE = 'DELETE',
    DETAILS = 'DETAILS',
    SHARE = 'SHARE',
    MOVE = 'MOVE',
    UNLINK_SHARE = 'UNLINK_SHARE',
    RESTORE = 'RESTORE',
    PERMANENT_DELETE = 'PERMANENT_DELETE',
}
export enum DefinedMenuName {
    PAGE_MENU = 'PAGE_MENU',
    BROWSE_FILE_MENU = 'BROWSE_FILE_MENU',
    BROWSE_FOLDER_MENU = 'BROWSE_FOLDER_MENU',
    SHARE_FILE_MENU = 'SHARE_FILE_MENU',
    SHARE_FOLDER_MENU = 'SHARE_FOLDER_MENU',
    TRASH_FILE_MENU = 'TRASH_FILE_MENU',
    TRASH_FOLDER_MENU = 'TRASH_FOLDER_MENU',
}
export enum ObjectType {
    file = 'file',
    folder = 'folder'
}
export enum ToastType{
    warning= 'warning',
    success= 'success',
    info='info',
    error= 'error',
}
export enum RestoreDestinationStatus{
    exist ='exist',
    notFound ='notFound',
    checking='checking',
    unset='unset'
}