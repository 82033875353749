import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';
import { Config } from '@/app/config';

export interface ISettingsState {
  theme: string
  fixedHeader: boolean
  showSettings: boolean
  showTagsView: boolean
  showSidebarLogo: boolean
  sidebarTextTheme: boolean
}

@Module({ dynamic: true, store, name: 'settings' })
class Settings extends VuexModule implements ISettingsState {
  public title= Config.APP_MAIN_TITLE;
  public theme = Config.PRIMARY_THEME_COLOR;
  public fixedHeader = true;
  public showSettings = false;
  public showTagsView = false;
  public showSidebarLogo = true;
  public sidebarTextTheme = false;

  @Mutation
  private CHANGE_SETTING(payload: { key: string, value: any }) {
    const { key, value } = payload;
    if (Object.prototype.hasOwnProperty.call(this, key)) {
      (this as any)[key] = value;
    }
  }

  @Action
  public ChangeSetting(payload: { key: string, value: any}) {
    this.CHANGE_SETTING(payload);
  }
}

export const SettingsModule = getModule(Settings);
