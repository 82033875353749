/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'delete': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 21.3c0 1.5 1.4 2.6 3 2.6h12c1.6 0 3-1.1 3-2.6V8.2c0-1.5-1.4-2.6-3-2.6H6c-1.6 0-3 1.1-3 2.6v13.2zM21.1 1.6h-3.7l-1-.9c-.2-.2-.7-.3-1-.3H9c-.3 0-.8.1-1 .3l-1.1.9H3c-.8 0-1.5.6-1.5 1.4S2.2 4.4 3 4.4h18.1c.8 0 1.5-.6 1.5-1.4s-.7-1.4-1.5-1.4z"/>'
  }
})
