/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'show_real_size': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M280-280v-320h-80v-80h160v400h-80zm160 0v-80h80v80h-80zm200 0v-320h-80v-80h160v400h-80zM440-440v-80h80v80h-80z"/>'
  }
})
