import { IFolder, IFile } from './../models/file';
import { MenuDetails, MenuKey, DefinedMenuName } from '@/models/app';

class ContextMenuService {
  public menuRef: any = null;
  public pageElement: HTMLElement | null = null;
  private menuDetails: { [key in DefinedMenuName]?: MenuDetails } = {}
  public registerMenu(newMenu: MenuDetails) {
    this.menuDetails[newMenu.menuName] = newMenu;
  }


  public closeMenu() {
    this.menuRef?.destroyMenu();
  }

  public showMenu(name: DefinedMenuName, event: MouseEvent, payload: IFile | IFolder | any, hideKey: MenuKey[] = []) {
    if (this.menuDetails[name] && this.menuRef) {
      const menuInstance = { ... this.menuDetails[name] };
      if(menuInstance){
        menuInstance.options = (menuInstance as MenuDetails).options.filter((menuItem) => !hideKey.includes(menuItem.key));
        this.menuRef?.activate(menuInstance, event, payload);
      }
    }
  }
}
export const contextMenu = new ContextMenuService();
