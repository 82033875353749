<template>
  <section ref="appMainPage" class="app-main" @contextmenu="showContextMenu($event)">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { MenuKey, DefinedMenuName, MenuOption, ObjectType, EmitterKey } from '@/models/app';
import { contextMenu } from '@/services/context-menu';
import EventBus from '@/app/event-bus';

@Component({
  name: 'AppMain',
})
export default class extends Vue {
  get key() {
    return this.$route.path;
  }
  mounted() {
    contextMenu.registerMenu({
      menuName: DefinedMenuName.PAGE_MENU,
      handler: this.menuOptionClicked,
      options: [
        { i18nLabel: 'uploadFile', key: MenuKey.UPLOAD_FILE, icon: 'el-icon-upload' },
        { i18nLabel: 'createFolder', key: MenuKey.CREATE_FOLDER, icon: 'el-icon-circle-plus' },
      ] as MenuOption[],
    });
    contextMenu.pageElement = this.$refs.appMainPage as HTMLElement;
  }

  menuOptionClicked(response: MenuOption) {
    if (response.key === MenuKey.UPLOAD_FILE) {
      EventBus.$emit(EmitterKey.PROMPT_CREATE_NEW, ObjectType.file);
    } else if (response.key === MenuKey.CREATE_FOLDER) {
      EventBus.$emit(EmitterKey.PROMPT_CREATE_NEW,  ObjectType.folder);
    }
  }

  showContextMenu(event: MouseEvent) {
    event.preventDefault();
    contextMenu.showMenu(DefinedMenuName.PAGE_MENU, event, null);
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 100px);
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header + .app-main {
  margin-top: 100px;
}

.hasTagsView {
  .app-main {
    /* 100 = navbar + breadcrumb= 50 + 50 */
    min-height: calc(100vh - 100px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>
