/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'add_circle': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.1.3C5.6.3.3 5.5.3 11.9s5.3 11.6 11.9 11.6 11.9-5.2 11.9-11.6S18.7.3 12.1.3zM18 13.1h-4.7v4.7h-2.4v-4.7H6.2v-2.3h4.7V6.1h2.4v4.7H18v2.3z"/>'
  }
})
