/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fit_width': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M120-120v-720h80v720h-80zm640 0v-720h80v720h-80zM280-440v-80h80v80h-80zm160 320v-80h80v80h-80zm0-160v-80h80v80h-80zm0-160v-80h80v80h-80zm0-160v-80h80v80h-80zm0-160v-80h80v80h-80zm160 320v-80h80v80h-80z"/>'
  }
})
