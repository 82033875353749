import Cookies from 'js-cookie';
export const cookieName = {
    token: 'app_access_token',
    size: 'size',
    language: 'active_language',
    sidebar: 'sidebar_status',
};

// App
export const getSidebarStatus = () => Cookies.get(cookieName.sidebar);
export const setSidebarStatus = (sidebarStatus: string) => Cookies.set(cookieName.sidebar, sidebarStatus);

export const getLanguage = () => Cookies.get(cookieName.language);
export const setLanguage = (language: string) => Cookies.set(cookieName.language, language);

export const getSize = () => Cookies.get(cookieName.size);
export const setSize = (size: string) => Cookies.set(cookieName.size, size);

// User
export const getToken = () => Cookies.get(cookieName.token);
export const setToken = (token: string) => Cookies.set(cookieName.token, token);
export const removeToken = () => Cookies.remove(cookieName.token);
