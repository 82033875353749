import { Config } from '@/app/config';
import i18n from '@/lang';
import { IFile, IFolder } from '@/models/file';
import { debounce } from 'lodash';

export const deepCopy = <T>(item: T): T => {
  return JSON.parse(JSON.stringify(item)) as T;
};

export const camelToKebab = (str: string): string => {
  const capsMatchRegExp = /[A-Z]/g;
  return str.replace(capsMatchRegExp, (match) => `-${match.toLowerCase()}`);
};

export const observeResizeEvent = (element: HTMLElement, eventName: string, debounceDelay: number): ResizeObserver => {
  const _brodcast = (element: HTMLElement, eventName: string): void => {
    const event = new CustomEvent(eventName, {
      detail: {
        width: element.offsetWidth,
      },
    });
    element.dispatchEvent(event);
  };
  const trigger = debounce(_brodcast, debounceDelay);
  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (element === entry.target) {
        trigger(element, eventName);
      }
    }
  });

  observer.observe(element);
  return observer;
};

export const promiseDelay = (delay: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
};

export const getScrollParent = (element: HTMLElement): HTMLElement | null => {
  //Note: The element only return if the scroll is available
  if (element == null) {
    return null;
  }
  if (element.scrollHeight > element.clientHeight) {
    return element;
  } else {
    return getScrollParent(element.parentElement as HTMLElement);
  }
};

export const scrollIntoViewIfNeeded = (element: HTMLElement, centerIfNeeded = false) => {
  const parent = getScrollParent(element);
  if (!parent) {
    return;
  }
  const parentComputedStyle = window.getComputedStyle(parent, null);
  const parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width'));
  const parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width'));
  const overTop = element.offsetTop - parent.offsetTop < parent.scrollTop;
  const overBottom = (element.offsetTop - parent.offsetTop + element.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight);
  const overLeft = element.offsetLeft - parent.offsetLeft < parent.scrollLeft;
  const overRight = (element.offsetLeft - parent.offsetLeft + element.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth);

  if ((overTop || overBottom) && centerIfNeeded) {
    parent.scrollTop = element.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + element.clientHeight / 2;
    if ((overLeft || overRight) && centerIfNeeded) {
      parent.scrollLeft = element.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + element.clientWidth / 2;
    }
    if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
    }
  }
};

export const isHTMLElement = (menuElement: any): menuElement is HTMLElement => {
  return menuElement instanceof HTMLElement;
};

export const directiveHandlerReference = (directiveName: string) => {
  //global object for storing fn ref; used in directives
  if (!(window as any)._directiveHandlerRefStore) {
    (window as any)._directiveHandlerRefStore = {};
  }
  const store = (window as any)._directiveHandlerRefStore;
  if (!store[directiveName]) {
    store[directiveName] = {};
  }
  return store[directiveName] as Record<string, Function>;
};



export const smartRound = (input: number): number => {
  /* This function only round the if the input has leading decimal value after 2 decimal places */
  const numStr = input.toString();
  if (numStr.indexOf('.') !== -1) {   // has decimal
      const decimalPlaces = numStr.split('.')[1].length; // Get the number of decimal places
      if (decimalPlaces > 2) {
          // If there are more than 2 decimal places, round to 2 decimal places
          return Math.round(input * 100) / 100;
      }
  }
  return input;
};

export const getHumanReadableFileSize = (fileSizeInBytes: number, unitBase: 1000 | 1024 = 1024): string => {
  let pointer = 0;
  const unitNames = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  while (Math.round(fileSizeInBytes) >= unitBase) {
      fileSizeInBytes = Math.round(fileSizeInBytes);
      fileSizeInBytes = fileSizeInBytes / unitBase;
      pointer++;
      if (pointer === unitNames.length) {
          break;
      }
  }
  return smartRound(fileSizeInBytes)+ ' ' + unitNames[pointer];
};

export const getSelectedItemType = (selectedItems:Array<IFile | IFolder>): 'folder'|'file'|'multiple'| null  => {
  if(selectedItems.length > 1){
    return 'multiple';
  }else if( selectedItems.length === Config.SINGLE_ITEM_ARRAY_LENGTH ){
    return selectedItems[0].type;
  }else{
    return null;
  }
};
/**
 * @description Returns a string with the type prefix and name of the object, e.g., "folder 'abc'" or "file 'xyz.png'".
 * @param {IFile | IFolder} objectItem - The object whose name and type are to be returned.
 * @param {number} objectNameLimit (Default 150) - The object name will be trimmed to this length if it exceeds it.
 * @returns {string} The object name prefixed with its type.
 */
export const getObjectNameWithItsTypePrefix  =(objectItem: IFile | IFolder, objectNameLimit:number = Config.NAME_FORMAT_MAX_NAME_LENGTH) =>{
  const itemType = getSelectedItemType([objectItem]);
  let itemName = objectItem?.name || '';
  if (itemName.length > objectNameLimit) {
    itemName = itemName.slice(0, objectNameLimit) + '...';
  }
  itemName = " '" + itemName + "'";
  if (itemType === 'file') {
    itemName = i18n.t('dialog.common.file').toString() + itemName;
  } else if (itemType === 'folder') {
    itemName = i18n.t('dialog.common.folder').toString() + itemName;
  } else {
    itemName = '';
  }
  return itemName;
};


export const isImageFile = (file: IFile) => {
  //Note: Add additional regular expression checking if need.
  const imageTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/webp',
  ];
  return imageTypes.some((mimeType) => file.mime_type.includes(mimeType));
};

export const  isPdfFile = (file: IFile) => {
  const PDF_MIME_TYPE_REG_EXP = /^application\/(x-)?pdf$/is;
  return PDF_MIME_TYPE_REG_EXP.test(file.mime_type);
};

export const  isMediaFile =(file: IFile) =>{
  const mediaMimeType = [
    'video/mp4',
    'video/ogg',
    'audio/aac',
    'audio/mpeg',
    'video/webm',
    'audio/webm'
  ];
  return mediaMimeType.some((mimeType) => file.mime_type.includes(mimeType));
};