<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="item.compiledPath">
        <span v-if="item.redirect === 'noredirect' || index === breadcrumbs.length - 1" class="no-redirect">
          <span v-if="item.breadcrumbType === 'Route'">{{ $t('route.' + item.display) }}</span>
          <span v-if="item.breadcrumbType === 'FilePath'">{{ item.display }}</span>
        </span>
        <a v-else @click.prevent="handleLink(item)">
          <span v-if="item.breadcrumbType === 'Route'">{{ $t('route.' + item.display) }}</span>
          <span v-if="item.breadcrumbType === 'FilePath'">{{ item.display }}</span>
        </a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script lang="ts">
import { fileState } from '@/services/file-state';
import { compile } from 'path-to-regexp';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { BreadcrumbHandler } from '@/models/app';
import { AppModule } from '@/store/modules/app';
import { IdMap } from '@/models/file';

@Component({
  name: 'AppBreadcrumb',
})
export default class extends Vue {
  private breadcrumbs: BreadcrumbHandler[] = []

  @Watch('$route')
  private onRouteChange(route: Route) {
    if (route.path.startsWith('/redirect/')) {
      return;
    }
    this.getBreadcrumb(route);
  }

  created() {
    this.getBreadcrumb(this.$route);
  }

  private getBreadcrumb(route: Route) {
    const matched = this.$route.matched.filter(item => item.meta && item.meta.title);
    this.breadcrumbs = [];
    matched.forEach(item => {
      if (item.meta && item.meta.title && item.meta.breadcrumb !== false) {
        this.breadcrumbs.push({
          path: item.path,
          redirect: item.redirect,
          compiledPath: this.pathCompile(item.path),
          display: item.meta.title,
          breadcrumbType: 'Route',
        });
      }
    });

    if (this.$route.name === 'browse' || this.$route.name === 'trash' || this.$route.name === 'shared') {
      const pathLookupRef:IdMap = fileState.trashObjectIdMap;
      const path = fileState.parsePathfromString((route?.query?.path as string) || '', pathLookupRef);
      path.forEach((_path, index) => {
        const levelPath = path.slice(0, index + 1);
        this.breadcrumbs.push({
          path: '',
          redirect: undefined,
          compiledPath: route.path + '?path=' + fileState.generateStringFromPath(levelPath),
          display: _path.name,
          breadcrumbType: 'FilePath',
        });
      });
    }
    AppModule.SetBreadCrumb(this.breadcrumbs);
  }

  private pathCompile(path: string) {
    const { params } = this.$route;
    const toPath = compile(path);
    return toPath(params);
  }

  private handleLink(item: BreadcrumbHandler) {
    const { redirect, compiledPath } = item;
    if (redirect) {
      this.$router.push(redirect).catch(err => {
        console.warn(err);
      });
      return;
    }
    this.$router.push(compiledPath).catch(err => {
      console.warn(err);
    });
  }
}
</script>

<style lang="scss" scoped>
.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}
.app-breadcrumb {
  width: 100%;
  position: relative;
}

.el-breadcrumb__item {
  display: inline-block;
}
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;
  margin-right: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }
}
</style>
