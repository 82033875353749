export interface UploadQueueItem {
    chunkList:UploadChunkItem[]
    name:string
    mime:string
    size:number
    parentFolderId:number
    status:UploadStatus
    fileId:string
}

export interface UploadChunkItem {
    uuid:string
    chunkItem:Blob
    size:number
    chunkStatus:UploadStatus
    retries:number
}

export enum UploadStatus{
    DONE='DONE',
    PAUSE='PAUSE',
    RESUME='RESUME_IN_PROGRESS',
    IN_PROGRESS='IN_PROGRESS',
    AWAITING='AWAITING',
    CANCELED='CANCELED',
    FAILED='FAILED',
}

export enum UploadUIStatus{
    UPLOAD_COMPLETE='UPLOAD_COMPLETE',
    UPLOAD_PROGRESS='UPLOAD_PROGRESS',
    UPLOAD_PAUSE='UPLOAD_PAUSE',
}
