import { BreadcrumbHandler } from '../../models/app';
import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { getSidebarStatus, getSize, setSidebarStatus, setLanguage, setSize } from '@/utils/cookies';
import { getLocale } from '@/lang';
import store from '@/store';
import {  UploadStatus } from '@/models/upload.models';
export enum DeviceType {
  Mobile,
  Desktop,
}


export interface IAppState {
  device: DeviceType
  sidebar: {
    opened: boolean
    withoutAnimation: boolean
  }
  uploadStatus: UploadStatus
  language: string
  size: string
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  public sidebar = {
    opened: getSidebarStatus() === 'opened',
    withoutAnimation: false
  }
  public uploadStatus = UploadStatus.AWAITING

  public device = DeviceType.Desktop
  public language = getLocale()
  public size = getSize() || 'medium'
  public breadCrumbData: BreadcrumbHandler[] = []

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = !this.sidebar.opened;
    this.sidebar.withoutAnimation = withoutAnimation;
    if (this.sidebar.opened) {
      setSidebarStatus('opened');
    } else {
      setSidebarStatus('closed');
    }
  }

  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = false;
    this.sidebar.withoutAnimation = withoutAnimation;
    setSidebarStatus('closed');
  }

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device;
  }

  @Mutation
  private SET_LANGUAGE(language: string) {
    this.language = language;
    setLanguage(this.language);
  }

  @Mutation
  private SET_SIZE(size: string) {
    this.size = size;
    setSize(this.size);
  }

  @Mutation
  private SET_BREADCRUMB(breadcrumb: BreadcrumbHandler[]) {
    this.breadCrumbData = breadcrumb;
    setSize(this.size);
  }
  @Mutation
  private SET_UPLOAD_STATUS(status: UploadStatus) {
    this.uploadStatus = status;
  }

  @Action
  public ToggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation);
  }

  @Action
  public CloseSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation);
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device);
  }

  @Action
  public SetLanguage(language: string) {
    this.SET_LANGUAGE(language);
  }

  @Action
  public SetSize(size: string) {
    this.SET_SIZE(size);
  }

  @Action
  public SetBreadCrumb(breadcrumb: BreadcrumbHandler[]) {
    this.SET_BREADCRUMB(breadcrumb);
  }
  @Action
  public SetUploadStatus(status: UploadStatus) {
    this.SET_UPLOAD_STATUS(status);
  }

}

export const AppModule = getModule(App);
