import moment from 'moment';
import { Route } from 'vue-router';
import { IResShareInfo } from '@/models/mock-models.d';
import { deepCopy, getHumanReadableFileSize, isImageFile, isMediaFile , isPdfFile} from '@/utils/common';
import { IFolder, IFile, IPath, IdMap, IShareInfo } from '@/models/file';
import { IResFile, IResFolder } from '@/models/mock-models';
import { getObjects } from './object-api';
import { getSharelist } from './share-api';

import i18n from '@/lang';

const ROOT_FOLDER_ID = 0;

const SORT_ASC = 'ascending';
const SORT_DESC = 'descending';
const SORT_BY_DISPLAY_SIZE_FIELD_NAME = 'displayFileSize';
const SORT_BY_SIZE_FIELD_NAME = 'size';
const SORT_BY_TYPE_FIELD_NAME = 'type';

class FileStateService {
  private browseFolderPartials: IFolder[] = [];
  private trashFolderPartials: IFolder[] = [];
  public trashObjectIdMap: IdMap = {};
  public browseObjectIdMap: IdMap = {};
  public currentActivePath = [];

  public getBrowseFolder(ObjectId: number, parentPath: IPath[], refresh = true): Promise<IFolder | null> {
    return this.lookUpFileOnTree(ObjectId, this.browseObjectIdMap).then(treelookUpResult => {
      if (treelookUpResult && !refresh) {
        return treelookUpResult;
      } else {
        return getObjects(ObjectId).then(folderId => {
          return this.processFolderObjects(folderId, parentPath);
        });
      }
    });
  }

  public getTrashFolder(ObjectId: number, parentPath: IPath[], refresh = false): Promise<IFolder | null> {
    return this.lookUpFileOnTree(ObjectId, this.trashObjectIdMap).then(treelookUpResult => {
      if (treelookUpResult && !refresh) {
        return treelookUpResult;
      } else {
        return getObjects(ObjectId).then(folderId => {
          return this.processFolderObjects(folderId, parentPath);
        });
      }
    });
  }

  public getSharedFiles(ObjectId: number, parentPath: IPath[], refresh = false): Promise<IFolder | null> {
    return this.lookUpFileOnTree(ObjectId, this.trashObjectIdMap).then(treelookUpResult => {
      if (treelookUpResult && !refresh) {
        return treelookUpResult;
      } else {
        return getSharelist(ObjectId).then(folderId => {
          return this.processFolderObjects(folderId, parentPath);
        });
      }
    });
  }

  public processFolderObjects(folderId: IResFile | IResFolder | null, parentPath: IPath[]): IFolder | null {
    if (folderId && this.isResponseFolder(folderId)) {
      const folderItem = this.processFolderItems(folderId, parentPath, this.trashObjectIdMap);
      this.mergeWithCurrentTree(folderItem, this.trashFolderPartials, this.trashObjectIdMap);
      return folderItem;
    } else {
      return null;
    }
  }

  public lookUpFileOnTree(id: number, map: IdMap): Promise<IFolder | null> {
    return new Promise(resolve => {
      try {
        const treelookUpResult = map[id] || null;
        if (treelookUpResult !== null && this.isFolder(treelookUpResult) && treelookUpResult.isChildrensFetched) {
          resolve(treelookUpResult);
        } else {
          resolve(null);
        }
      } catch (e) {
        console.log('Error occured: lookUpFileOnTree', e);
      }
    });
  }

  public deleteBrowseFile(id: number) {
    delete this.browseObjectIdMap[id];
  }

  private mergeWithCurrentTree(folderItem: IFolder, partialList: IFolder[], IdBasedObjectMap: IdMap) {
    try {
      const firstLevelIndex: { [id: number]: IFolder } = {};
      partialList.forEach(item => {
        firstLevelIndex[item.id] = item;
      });
      const idAlreadyExist = !!IdBasedObjectMap[folderItem.id];
      IdBasedObjectMap[folderItem.id] = folderItem;
      if (!idAlreadyExist) {
        partialList.push(folderItem);
      }
      folderItem.contents.forEach(child => {
        if (this.isFolder(child)) {
          const childItemOnPartialTree = firstLevelIndex[child.id];
          if (childItemOnPartialTree) {
            child = childItemOnPartialTree;
            const index = partialList.findIndex(_partialEnity => _partialEnity.id === childItemOnPartialTree.id);
            partialList.splice(index, 1);
          }
        }
        IdBasedObjectMap[child.id] = child;
      });
    } catch (e) {
      console.log('Error occured: mergeWithCurrentTree', e);
    }
  }

  private processFolderItems(folderObject: IResFolder, parentPath: IPath[], folderMap: IdMap): IFolder {
    try {
      const processedFolder: IFolder = {
        id: folderObject.id,
        name: folderObject.name,
        type: 'folder',
        icon:'el-icon-folder',
        path: parentPath,
        isChildrensFetched: true,
        shares: this.processShareInfo(folderObject.shares),
        contents: [],
        created_at: folderObject.created_at,
        modified_at: folderObject.modified_at,
        deleted_at: folderObject.deleted_at,
        shared_at: folderObject.shared_at,
        created_by: folderObject.created_by,
        displayLastModified: '',
        displayDeletedAt: '',
        displayFileSize: '-',
        displaySharedAt: '',
        timestamp: {
          created: this.timeStampFromDateString(folderObject.created_at),
          modified: this.timeStampFromDateString(folderObject.modified_at),
          deleted: this.timeStampFromDateString(folderObject.deleted_at),
          shared: this.timeStampFromDateString(folderObject.shared_at),
        },
        details: folderObject.details
      };
      processedFolder.displayLastModified = this.lastModifiedDateString(processedFolder);
      processedFolder.displayDeletedAt = this.lastDeletedDateString(processedFolder);
      processedFolder.displaySharedAt = this.sharedDateString(processedFolder);
      folderMap[processedFolder.id] = processedFolder;
      const childPath: IPath[] = deepCopy(parentPath);
      childPath.push({
        id: processedFolder.id,
        name: processedFolder.name,
      });
      (folderObject.contents || []).forEach(child => {
        if (this.isResponseFolder(child)) {
          const _childFolder = {
            id: child.id,
            name: child.name,
            type: 'folder',
            icon:'el-icon-folder',
            path: childPath,
            isChildrensFetched: false,
            shares: this.processShareInfo(child.shares),
            contents: [],
            created_at: child.created_at,
            modified_at: child.modified_at || null,
            deleted_at: child.deleted_at || null,
            created_by: child.created_by,
            shared_at: child.shared_at,
            displayLastModified: '',
            displayDeletedAt: '',
            displayFileSize: '-',
            displaySharedAt: '',
            timestamp: {
              created: this.timeStampFromDateString(child.created_at),
              modified: this.timeStampFromDateString(child.modified_at),
              deleted: this.timeStampFromDateString(child.deleted_at),
              shared: this.timeStampFromDateString(child.shared_at),
            },
            details: child.details
          } as IFolder;
          _childFolder.displayLastModified = this.lastModifiedDateString(_childFolder);
          _childFolder.displayDeletedAt = this.lastDeletedDateString(_childFolder);
          _childFolder.displaySharedAt = this.sharedDateString(_childFolder);
          processedFolder.contents.push(_childFolder);
        } else if (this.isResponseFIle(child)) {
          const _childFile = {
            id: child.id,
            name: child.name,
            icon:'el-icon-s-management',
            type: 'file',
            path: childPath,
            source: child.source||'',
            shares: this.processShareInfo(child.shares),
            size: child.size,
            download_link: child.download_link,
            created_at: child.created_at,
            mime_type: child.mime_type,
            modified_at: child.modified_at || null,
            deleted_at: child.deleted_at || null,
            created_by: child.created_by,
            shared_at: child.shared_at,
            displayLastModified: '',
            displayDeletedAt: '',
            displayFileSize: '-',
            displaySharedAt: '',
            timestamp: {
              created: this.timeStampFromDateString(child.created_at),
              modified: this.timeStampFromDateString(child.modified_at),
              deleted: this.timeStampFromDateString(child.deleted_at),
              shared: this.timeStampFromDateString(child.shared_at),
            },
          } as IFile;
          try {
            _childFile.displayFileSize = getHumanReadableFileSize(_childFile.size);
          } catch (e) {
            const error = e as Error;
            if (error.message === 'filesize_unavailable') {
              _childFile.displayFileSize = i18n.t('fileList.unavailable').toString();
            }
          }
          _childFile.displayLastModified = this.lastModifiedDateString(_childFile);
          _childFile.displayDeletedAt = this.lastDeletedDateString(_childFile);
          _childFile.displaySharedAt = this.sharedDateString(_childFile);
          if(isPdfFile(_childFile)){
            _childFile.icon ='el-icon-document';
          }else if(isMediaFile(_childFile)){
            _childFile.icon ='el-icon-video-play';
          } else if(isImageFile(_childFile)){
            _childFile.icon ='el-icon-picture';
          }
          processedFolder.contents.push(_childFile);
        }
      });
      return processedFolder;
    } catch (e) {
      console.log('Error occured: processFolderItems', e);
      return folderObject as IFolder;
    }
  }

  public processShareInfo(resSharedList: IResShareInfo[]): IShareInfo[] {
    let sharedList: IShareInfo[] = [];
    if (Array.isArray(resSharedList)) {
      sharedList = resSharedList as IShareInfo[];
    }
    sharedList.forEach(shr => {
      shr.timestamp_shareAt = this.timeStampFromDateString(shr.shared_at) as number;
      shr.displayShareAt = moment(shr.shared_at).format('MMM DD, YYYY HH:mm A');
    });
    return sharedList;
  }

  public parsePathfromString(pathString: string, refObject: IdMap): IPath[] {
    /**
     * @param pathString =  '/1/documents/32/acta-documents/28/APR-Docs/432/march-2020/433/xls%20files/
     * @return IMap[]  =  [{"id":"1","name":"documents"},{"id":"32","name":"acta-documents"},{"id":"28","name":"APR-Docs"},{"id":"432","name":"march-2020"},{"id":"433","name":"xls%20files"}]
     */
    try {
      let pathArray = pathString.replace(/(^\/|\/$)/gi, '').split('/');
      const PathObjectArray: IPath[] = [];
      pathArray = pathArray.filter(x => x.trim()); // removing blank values
      for (let index = 0; index < pathArray.length; index += 2) {
        const id = parseInt(pathArray[index]);
        let name = decodeURIComponent(pathArray[index + 1].replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'));
        if (Number.isInteger(id) && name) {
          const _ObjectInState = refObject[id];

          if (_ObjectInState) { // NOTE  to update name of old url parts,
            name = _ObjectInState.name;
          }
          PathObjectArray.push({ id, name });
        }
      }
      return PathObjectArray;
    } catch (e) {
      console.log('Error occured: parsePathfromString', e);
      return [];
    }
  }

  public generateStringFromPath(path: IPath[]): string {
    /**
     * @param IMap[]  =  [{"id":"1","name":"documents"},{"id":"32","name":"acta-documents"},{"id":"28","name":"APR-Docs"},{"id":"432","name":"march-2020"},{"id":"433","name":"xls%20files"}]
     * @return pathString =  '/1/documents/32/acta-documents/28/APR-Docs/432/march-2020/433/xls%20files/
     */
    try {
      let pathString = '';
      for (let index = 0; index < path.length; index++) {
        pathString += '/' + path[index].id + '/' + path[index].name;
      }
      return pathString;
    } catch (e) {
      console.log('Error occured: generateStringFromPath', e);
      return '';
    }
  }

  private timeStampFromDateString(dateString: string | null): number | null {
    try {
      if (dateString) {
        const momentInstance = moment(dateString);
        return momentInstance.isValid() ? momentInstance.valueOf() : null;
      } else {
        return null;
      }
    } catch (e) {
      console.log('Error occured: timeStampFromDateString', e);
      return null;
    }
  }

  private lastModifiedDateString(item: IFolder | IFile) {
    const date = item.timestamp.modified || item.timestamp.created;
    if (date) {
      return moment(date).format('MMM DD, YYYY HH:mm A');
    } else {
      return '';
    }
  }

  private lastDeletedDateString(item: IFolder | IFile) {
    const date = item.timestamp.deleted;
    if (date) {
      return moment(date).format('MMM DD, YYYY HH:mm A');
    } else {
      return '';
    }
  }

  private sharedDateString(item: IFolder | IFile) {
    const date = item.timestamp.shared;
    if (date) {
      return moment(date).format('MMM DD, YYYY HH:mm A');
    } else {
      return '';
    }
  }


  private isResponseFolder(item: IResFile | IResFolder): item is IResFolder {
    return (
      item &&
      typeof item === 'object' &&
      item.type === 'folder' &&
      !Object.prototype.hasOwnProperty.call(item, 'path') &&
      !Object.prototype.hasOwnProperty.call(item, 'isChildrensFetched')
    );
  }

  private isResponseFIle(item: IResFile | IResFolder): item is IResFile {
    return (
      item &&
      typeof item === 'object' &&
      item.type === 'file' &&
      !Object.prototype.hasOwnProperty.call(item, 'path') &&
      !Object.prototype.hasOwnProperty.call(item, 'isChildrensFetched')
    );
  }

  public isFolder(item: any): item is IFolder {
    return (
      item &&
      typeof item === 'object' &&
      item.type === 'folder' &&
      Object.prototype.hasOwnProperty.call(item, 'path') &&
      Object.prototype.hasOwnProperty.call(item, 'isChildrensFetched')
    );
  }

  public isFile(item: any): item is IFile {
    return (
      item &&
      typeof item === 'object' &&
      item.type === 'file' &&
      Object.prototype.hasOwnProperty.call(item, 'path') &&
      !Object.prototype.hasOwnProperty.call(item, 'isChildrensFetched')
    );
  }

  nameUpdate(object: IFolder | IFile) {
    if (this.browseObjectIdMap[object.id]) {
      this.browseObjectIdMap[object.id].name = object.name;
    }
  }

  movePathUpdate(movePathUpdate: IFolder, target: IFolder) {
    const folder = this.browseObjectIdMap[movePathUpdate.id] as IFolder;
    if (folder && folder.contents) {
      folder.contents.push(target);
      folder.isChildrensFetched = true;
    }
  }

  moveObjects(targetid: number, destinationid: number) {
    const targetObject = this.browseObjectIdMap[targetid];
    const destinationObject = this.browseObjectIdMap[destinationid];
    if (targetObject && this.isFolder(destinationObject)) {
      let parentIdOfTarget = 0;
      if (targetObject.path.length > 0) {
        parentIdOfTarget = targetObject.path[targetObject.path.length - 1]?.id;
      }
      const parentOfTarget = this.browseObjectIdMap[parentIdOfTarget];
      if (Number.isInteger(parentIdOfTarget) && this.isFolder(parentOfTarget)) {
        const folderList = parentOfTarget?.contents || [];
        const targetIndex = folderList.findIndex((x) => targetid === x.id);
        if (targetIndex !== -1) {
          folderList.splice(targetIndex, 1);
        }
        const path = deepCopy(destinationObject.path);
        path.push({
          id: destinationObject.id,
          name: destinationObject.name
        });
        targetObject.path = path;
        destinationObject.contents.push(targetObject);
      } else {
        console.log('File Move error Unable to find Parent of target');
      }
    } else {
      console.log('Error target or destination isn\'t found in state tree');
    }
  }

  openFromRoute(route?: Route) {
    return new Promise((resolve) => {
      if (!route) {
        resolve({ id: ROOT_FOLDER_ID, path: [] });
      } else {
        const activePath = fileState.parsePathfromString((route?.query?.path as string) || '', fileState.browseObjectIdMap);
        if (activePath.length) {
          const folderTobeOpened = activePath[activePath.length - 1];
          const path = activePath.slice(0, activePath.length - 1);
          if (folderTobeOpened && path) {
            resolve({ id: folderTobeOpened.id, path });
          } else {
            resolve({ id: ROOT_FOLDER_ID, path: [] });
          }
        } else {
          resolve({ id: ROOT_FOLDER_ID, path: [] });
        }
      }
    });
  }

  getFileDownloadUrl(id: number, objectType: string) {
    return `${process.env.VUE_APP_API_BASE}/${objectType}/download/${id}`;
  }

  download(id: number, filename: string, objectType: string) {
    const a = document.createElement('a');
    a.href = this.getFileDownloadUrl(id,objectType);
    a.download = filename;
    a.target = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      a.remove();
    }, 500);
  }

  public groupObjectLists(objectLists: Array<IFile | IFolder> | undefined, sortFieldName: string, sortOrder: string, groupObjects = true):Array<IFile | IFolder> {
    const sortBy = sortFieldName === SORT_BY_DISPLAY_SIZE_FIELD_NAME ? SORT_BY_SIZE_FIELD_NAME : sortFieldName;
    const sortDynamic = (fieldName: any, order = SORT_ASC) => {
      const sortOrder = order === SORT_ASC ? 1 : -1;
      return (firstElement: any, secondElement: any) => {
        const firstElementValue = (typeof firstElement[fieldName] === 'string') ? firstElement[fieldName].toUpperCase() : firstElement[fieldName];
        const secondElementValue = (typeof secondElement[fieldName] === 'string') ? secondElement[fieldName].toUpperCase() : secondElement[fieldName];
        if (firstElementValue < secondElementValue) {
          return sortOrder * -1;
        } else if (firstElementValue > secondElementValue) {
          return sortOrder * 1;
        }
        return 0;
      };
    };
    const filteredObjects = (objectLists ||[]).sort(sortDynamic(sortBy, sortOrder));
    if (groupObjects || sortBy === SORT_BY_SIZE_FIELD_NAME) {
      const foldersSortOrder =
        (sortBy === SORT_BY_SIZE_FIELD_NAME && sortOrder === SORT_ASC)
          ? sortOrder
          : (sortOrder === SORT_ASC)
            ? SORT_DESC
            : sortOrder;
      return filteredObjects.sort(sortDynamic(SORT_BY_TYPE_FIELD_NAME, foldersSortOrder));
    }
    return filteredObjects;
  }
}
export const fileState = new FileStateService();
