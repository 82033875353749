<template>
  <div :class="classObj" class="app-wrapper">
    <div v-if="classObj.mobile && sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <app-sidebar class="sidebar-container" />
    <div :class="{hasTagsView: showTagsView}" class="main-container">
      <div :class="{'fixed-header': fixedHeader}">
        <app-head />
      </div>
      <app-main />
      <app-context-menu />
    </div>
    <app-footer />
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DeviceType, AppModule } from '@/store/modules/app';
import { SettingsModule } from '@/store/modules/settings';
import AppMain from '@/layout/partials/app-main.vue';
import AppContextMenu from '@/layout/partials/app-context-menu.vue';
import AppFooter from '@/layout/partials/app-footer.vue';
import AppHead from '@/layout/partials/app-head.vue';
import AppSidebar from '@/layout/partials/app-sidebar.vue';
import MoveDialog from '@/layout/partials/move-dialog.vue';
import ResizeMixin from '@/mixins/resize';

@Component({
  name: 'MainLayout',
  components: {
    AppMain,
    AppHead,
    AppFooter,
    AppSidebar,
    AppContextMenu,
    MoveDialog,
  }
})
export default class extends mixins(ResizeMixin) {
  get classObj() {
    return {
      hideSidebar: !this.sidebar.opened,
      openSidebar: this.sidebar.opened,
      withoutAnimation: this.sidebar.withoutAnimation,
      mobile: this.device === DeviceType.Mobile
    };
  }

  get showSettings() {
    return SettingsModule.showSettings;
  }

  get showTagsView() {
    return SettingsModule.showTagsView;
  }

  get fixedHeader() {
    return SettingsModule.fixedHeader;
  }

  private handleClickOutside() {
    AppModule.CloseSideBar(false);
  }
}
</script>

<style lang="scss" scoped>
@import '@style/variables';
.app-wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.main-container {
  min-height: 100%;
  transition: margin-left 0.28s;
  margin-left: $sideBarWidth;
  position: relative;
}

.sidebar-container {
  transition: width 0.28s;
  width: $sideBarWidth !important;
  height: 100%;
  position: fixed;
  font-size: 0px;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar {
  .main-container {
    margin-left: 54px;
  }

  .sidebar-container {
    width: 54px !important;
  }

  .fixed-header {
    width: calc(100% - 54px);
  }
}

.mobile {
  .main-container {
    margin-left: 0px;
  }

  .sidebar-container {
    transition: transform 0.28s;
    width: $sideBarWidth !important;
  }

  &.openSidebar {
    position: fixed;
    top: 0;
  }

  &.hideSidebar {
    .sidebar-container {
      pointer-events: none;
      transition-duration: 0.3s;
      transform: translate3d(-$sideBarWidth, 0, 0);
    }
  }

  .fixed-header {
    width: 100%;
  }
}

.withoutAnimation {
  .main-container,
  .sidebar-container {
    transition: none;
  }
}
.logo-image{
  height: 80px;
}
</style>