/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'documentation': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.7 8.9h6.5l-6.5-6.4v6.4zM5.5.7h9.4l7 7v14.1c0 .6-.2 1.2-.7 1.7-.4.4-1 .7-1.7.7h-14c-.6 0-1.2-.2-1.7-.7-.4-.4-.7-1-.7-1.7V3C3.1 1.7 4.2.7 5.5.7zm10.6 18.7v-2.3H5.5v2.3h10.6zm3.5-4.7v-2.3H5.5v2.3h14.1z"/>'
  }
})
