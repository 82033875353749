<template>
  <div>
    <el-dialog
      :before-close="beforeClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title"
      :visible.sync="moveDialogVisible"
      :append-to-body="true"
      width="30%"
      center
    >
      <div v-loading="initialLoading">
        <h4>{{ heading }}</h4>
        <el-input v-model="filterText" :placeholder="$t('dialog.move.filterPlaceHolder')" />
        <el-tree
          v-if="moveDialogVisible"
          ref="tree"
          :data="treeData"
          :props="defaultProps"
          :load="loadNode"
          :empty-text="$t('dialog.move.foldersNotFound')"
          :expand-on-click-node="true"
          :highlight-current="true"
          icon-class="el-icon-folder"
          :filter-node-method="filterNode"
          lazy
          class="filter-tree"
          @node-click="nodeSelected"
        >
          <span slot-scope="{node, data}" class="el-tree-node__label" :class="{'disabled': !isTargetAllowed(data)}">
            {{ node.label }}
            <el-tooltip v-if="!isTargetAllowed(data)" class="info-icon" effect="dark" :content="$t('dialog.move.sameFolderWarning')" placement="top-start">
              <i class="el-icon-question" />
            </el-tooltip>
          </span>
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()">
          {{ $t('dialog.move.cancelButtonText') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="!isTargetAllowed(selectedDestinationFolder)"
          @click="movetoSelectedFolder()"
        >
          {{ $t('dialog.move.moveButton') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Tree } from 'element-ui';
import { moveDialog } from '@/services/move-dialog';
import { IFile, IFolder, IPath } from '@/models/file';
import { fileState } from '@/services/file-state';
import { deepCopy, getSelectedItemType } from '@/utils/common';
import i18n from '@/lang';
import objectService from '@/services/object-service';

@Component({
  name: 'MoveDialog',
  props:['sortingDetails']
})
export default class extends Vue {
  public treeData = null;
  public defaultProps = {
    label: 'name',
    children: 'content',
    isLeaf: () => {
      return 'leaf';
    },
  };

  public title = '';
  public heading = '';
  public initialLoading = false;
  public moveDialogVisible = false;
  public filterText = '';
  public sourceItems: Array<IFolder | IFile> = [];
  public finishCallBack: ((id: IFolder) => void) | null = null;
  public selectedDestinationFolder: IFolder | null = null;

  mounted() {
    moveDialog.dialogRef = this;
  }

  isTargetAllowed(data:any) {
    return objectService.activeFolderId !== data?.id;
  }

  nodeSelected(folder: IFolder) {
    this.selectedDestinationFolder = folder;
  }

  loadNode(node: any, resolve: any) {
    if (this.moveDialogVisible) {
      let folderId = 0;
      const path: IPath[] = [];
      if (node.data !== null) {
        folderId = node.data.id || 0;
        if (node?.data?.path && Array.isArray(node.data.path)) {
          (node?.data?.path as IPath[]).forEach((_item) => {
            path.push({
              id: _item.id,
              name: _item.name,
            });
          });
        }
      } else {
        this.initialLoading = true;
      }
      fileState.getBrowseFolder(folderId, path, true).then((_folder: IFolder | null) => {
        this.initialLoading = false;
        if (_folder) {
          const folder = deepCopy(_folder);
          const sortingDetails = (this as any).sortingDetails;
          folder.contents = fileState.groupObjectLists(folder.contents, sortingDetails.props, sortingDetails.order);
          const chosenItemIdList:number[] = this.sourceItems.map(x => x.id);
          folder.contents = folder.contents.filter(object => {
            return (fileState.isFolder(object)) && !chosenItemIdList.includes(object.id);
          }) as IFolder[];
          node.data = folder;
          resolve(folder.contents);
        } else {
          resolve([]);
        }
      });
    }
  }

  @Watch('filterText')
  filterTextChange(val: string) {
    (this.$refs.tree as Tree).filter(val);
  }

  filterNode(value: string, data: IFolder) {
    if (!value) return true;
    return data.name.indexOf(value) !== -1;
  }

  open(sourceItems: Array<IFolder | IFile>, callback: (id: IFolder) => void) {
    this.sourceItems = sourceItems;
    this.finishCallBack = callback as (item: IFolder) => void;
    this.selectedDestinationFolder = null;
    this.moveDialogVisible = true;
    this.setDialogTitle();
  }

  setDialogTitle(){
    const sourceItemType = getSelectedItemType(this.sourceItems);
    const messageMap= {
      folder:'folder',
      file:'file',
      multiple: 'items',
    } as const;
    const typeName = i18n.t('dialog.common.' + messageMap[sourceItemType || 'multiple']);
    this.title = i18n.t('dialog.move.title', { type: typeName }).toString();
    let fileName = '';
    if (sourceItemType === 'multiple') {
      fileName = i18n.t('dialog.common.selectedItems').toString();
    } else {
      fileName = this.sourceItems[0].name;
    }
    this.heading = i18n.t('dialog.move.heading', { name: fileName }).toString();
  }

  close() {
    this.beforeClose();
    this.moveDialogVisible = false;
  }

  beforeClose(done?: () => void) {
    this.filterText = '';
    this.sourceItems = [];
    this.finishCallBack = null;
    this.selectedDestinationFolder = null;
    done && done();
  }

  movetoSelectedFolder() {
    if (!this.selectedDestinationFolder && this.isTargetAllowed(this.selectedDestinationFolder)) {
      this.$alert(
        i18n.t('dialog.move.moveAlert.message').toString(),
        i18n.t('dialog.move.moveAlert.title').toString(),
        {
          confirmButtonText: i18n.t('dialog.move.moveAlert.ok').toString(),
          closeOnClickModal: true,
          closeOnPressEscape: true,
          closeOnHashChange: true,
        }
      );
      return;
    }
    this.moveDialogVisible = false;
    if (this.finishCallBack && fileState.isFolder(this.selectedDestinationFolder)) {
      this.finishCallBack(this.selectedDestinationFolder);
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-tree {
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
  :deep(.el-tree-node__children:not(.v-leave-active):not(.v-enter-active)) {
    overflow: visible !important;
    width: auto;
  }
  :deep(.el-tree-node) {
    &.is-current > .el-tree-node__content {
      background-color: #e6f3ff;
      font-weight: 600;
    }
    .el-tree-node__content {
      width: fit-content;
      min-width: 100%;
      .el-tree-node__label{
        color:#000000;
        &.disabled{
          color: #b8b6b6;
          text-decoration: line-through;
        }
        .info-icon{
          margin-left:2px;
        }

      }
      .el-tree-node__expand-icon {
        &.el-icon-folder {
          &.is-leaf {
            &::before {
              color: #c0c4cc;
              content: '\e78a' !important;
            }
          }
          &.expanded {
            &::before {
              content: '\e784' !important;
            }
          }
        }
      }
    }
  }
}
</style>
