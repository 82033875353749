import { moveDialog } from '../services/move-dialog';
import { contextMenu } from '../services/context-menu';
import i18n from '@/lang';
import { SettingsModule } from '@/store/modules/settings';
import Vue from 'vue';
import Router, { Route, RouteConfig } from 'vue-router';
import Layout from '@/layout/main-layout.vue';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
NProgress.configure({ showSpinner: false });
Vue.use(Router);

export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Layout,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/pages/misc/redirect.vue')
      }
    ]
  },
  {
    path: '/auth-redirect',
    component: () => import('@/pages/misc/auth-redirect.vue'),

  },
  {
    path: '/',
    component: Layout,
    redirect: '/browse',
    children: [
      {
        path: 'browse',
        component: () => import('@/pages/browse.vue'),
        name: 'browse',
        meta: {
          breadcrumb: true,
          title: 'home',
        },
      },
      {
        path: 'trash',
        component: () => import('@/pages/trash.vue'),
        name: 'trash',
        meta: {
          breadcrumb: true,
          title: 'trash',
        }
      },
      {
        path: 'shared',
        component: () => import('@/pages/shared.vue'),
        name: 'shared',
        meta: {
          breadcrumb: true,
          title: 'shared',
        }
      },
    ],
  },
  {
    path: '**',
    component: () => import('@/pages/error-page/404.vue'),

  },
];
const createRouter = () =>
  new Router({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  });
const router = createRouter();
export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher;
}

const getPageTitle = (to: Route) => {
  const hasKey = i18n.te(`route.${to?.meta?.title}`);
  if (to.query && Object.keys(to.query).length) {
    const query = to.query.path.toString().split('/');
    if (query.length) {
      return `${query[query.length - 1]} - ${SettingsModule.title}`;
    }
  }
  if (hasKey && to?.meta?.title !== 'home') {
    const pageName = i18n.t(`route.${to?.meta?.title}`);
    return `${pageName} - ${SettingsModule.title}`;
  }
  return `${SettingsModule.title}`;
};

router.beforeEach(async(to: Route, from: Route, next: any) => {
  contextMenu.closeMenu();
  moveDialog.closeDialog();
  NProgress.start();
  next();
});

router.afterEach((to: Route) => {
  const willTrunofLoader = ['browse', 'trash'];
  if (willTrunofLoader.includes(to?.name || '')) {
    NProgress.inc();
  } else {
    NProgress.done();
  }
  document.title = getPageTitle(to);
});

export default router;
