<template>
  <div ref="menuEl" class="context-menu">
    <ul class="custom-menu">
      <li v-for="item in menuItems" :key="item.key" @click="selected(item)">
        <i class="icons" :class="item.icon" />
        {{ $t('contextMenu.' + item.i18nLabel) }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { contextMenu } from '@/services/context-menu';
import { MenuDetails, MenuOption } from '@/models/app';
import { IFile, IFolder } from '@/models/file';
import { getScrollParent, isHTMLElement, scrollIntoViewIfNeeded } from '@/utils/common';
import { debounce } from 'lodash';

@Component({
  name: 'AppContextMenu',
})
export default class extends Vue {
  public callBack: null | ((response: MenuOption, payload: IFile | IFolder | any) => void) = null;
  public menuItems: MenuOption[] = [];
  public payload: IFile | IFolder | null = null;

  mounted() {
    contextMenu.menuRef = this;
  }

  beforeDestroy() {
    this.destroyMenu();
  }

  selected(item: MenuOption) {
    this.setMenuVisibility('HIDE');
    if (this.callBack) {
      this.callBack(item, this.payload);
    }
  }

  keyDownEvent = debounce(
    (() => {
      this.destroyMenu();
    }).bind(this),
    400,
    { leading: true }
  );

  resizeEvent = debounce(
    (() => {
     this.destroyMenu();
    }).bind(this),
    400,
    { leading: true }
  );

  outSideClick = debounce(
    ((event: MouseEvent) => {
      if ((event.target as Element).closest('.context-menu') !== this.$refs.menuEl) {
        event.preventDefault();
        this.destroyMenu();
      }
    }).bind(this),
    400,
    { leading: true }
  );

  async activate(menuDetails: MenuDetails, event: MouseEvent, payload: IFile | IFolder) {
    this.destroyMenu(); // Clean previous menu;
    document.addEventListener('mousedown', this.outSideClick);
    document.addEventListener('keydown', this.keyDownEvent);
    window.addEventListener('resize', this.resizeEvent);

    this.menuItems = menuDetails.options;
    this.callBack = menuDetails.handler;
    this.payload = payload;
    await Vue.nextTick(); // wait to render the list
    if (isHTMLElement(this.$refs.menuEl)) {
      this.setMenuPosition(event);
      scrollIntoViewIfNeeded(this.$refs.menuEl);
    }
  }

  setMenuPosition(event: MouseEvent) {
    if (isHTMLElement(this.$refs.menuEl) && isHTMLElement(contextMenu.pageElement)) {
      //get page size prior to menu display
      const positionBoundaryPadding = 10; //in px;

      let scrollParent = getScrollParent(this.$refs.menuEl);
      if (!scrollParent) {
        scrollParent = document.documentElement;
      }
      const pageHeight = contextMenu.pageElement.offsetHeight;
      const pageWidth = contextMenu.pageElement.offsetWidth;
      this.setMenuVisibility('SHOW');
      //get menu size after menu display
      const menuHeight = this.$refs.menuEl.offsetHeight;
      const menuWidth = this.$refs.menuEl.offsetWidth;

      const { top: pageTopMargin, left: pageLeftMargin } = contextMenu.pageElement.getBoundingClientRect();

      // Calculating page boundary with padding
      const pageXStart = positionBoundaryPadding;
      const pageXEnd = pageWidth - positionBoundaryPadding;
      const pageYStart = positionBoundaryPadding;
      const pageYEnd = pageHeight - positionBoundaryPadding;

      let menuXPosition = event.pageX - (pageLeftMargin + scrollParent.scrollLeft);
      let menuYPosition = event.pageY - (pageTopMargin + scrollParent.scrollTop);

      if (menuXPosition + menuWidth > pageXEnd) {
        menuXPosition = pageXEnd - menuWidth;
      }
      if (menuXPosition < pageXStart) {
        menuXPosition = pageXStart;
      }
      if (menuYPosition + menuHeight > pageYEnd) {
        menuYPosition = pageYEnd - menuHeight;
      }
      if (menuYPosition < pageYStart) {
        menuYPosition = pageYStart;
      }

      this.$refs.menuEl.style.left = menuXPosition + 'px';
      this.$refs.menuEl.style.top = menuYPosition + 'px';
    }
  }

  setMenuVisibility(value: 'SHOW' | 'HIDE' = 'SHOW') {
    if (isHTMLElement(this.$refs.menuEl)) {
      if (value === 'SHOW') {
        this.$refs.menuEl.classList.add('show');
      } else {
        this.$refs.menuEl.classList.remove('show');
      }
    }
  }

  get isMenuVisible() {
    if (isHTMLElement(this.$refs.menuEl)) {
      return this.$refs.menuEl.classList.contains('show');
    }
    return false;
  }

  destroyMenu() {
    document.removeEventListener('mousedown', this.outSideClick);
    document.removeEventListener('keydown', this.keyDownEvent);
    window.removeEventListener('resize', this.resizeEvent);

    this.setMenuVisibility('HIDE');
    this.menuItems = [];
    this.callBack = null;
    this.payload = null;
  }
}
</script>

<style lang="scss" scoped>
.context-menu {
  display: none;
  z-index: 1;
  width: 160px;
  position: absolute;
  overflow: hidden;
  padding: 0px;
  white-space: nowrap;
  background: #fff;
  font-size: 12px;
  box-shadow: 1px 1px 6px 2px #64646442;
  &.show {
    display: block !important;
  }
  &:before {
    position: absolute;
    left: -20px;
    width: 20px;
    top: 2px;
    height: 5px;
    border: 10px solid #0000;
    border-right-color: #f1eeee;
    background-color: #0000;
    content: '';
  }
}
.custom-menu {
  display: block;
  list-style: none;
  padding: 0px;
  margin: 0px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;

  li {
    padding: 10px 12px;
    cursor: pointer;
    border-bottom: 1px solid #cccccc;
    color: #053678;
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: #def;
    }
    .icons {
      margin-right: 4px;
    }
  }
}
</style>
