<template>
  <header class="app-header">
    <div class="topbar">
      <app-hamburger
        id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
        @toggleClick="toggleSideBar"
      />

      <el-menu class="el-menu-header" mode="horizontal">
        <el-submenu v-if="appsList.length" index="main_1">
          <template slot="title">
            <i class="el-icon-s-grid" />
            <span>{{ currentApp?.name }}</span>
          </template>
          <el-menu-item v-for="(app,index) in appsList" :key="app.identification_key" :index="'sub_'+index">
            <a class="acta-app-menu-item" :href="app.link" target="_blank"> {{ app.name }}</a>
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="main_2" @click="logout()">
          <div class="logout-menu">
            <svg-icon class="logout-icon" name="logout" />
            <span slot="title"> {{ $t('route.logout') }} </span>
          </div>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="navbar">
      <app-breadcrumb />
    </div>
  </header>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import AppBreadcrumb from '@/layout/partials/app-breadcrumb.vue';
import AppHamburger from '@/layout/partials/app-hamburger.vue';
import { Config } from '@/app/config';
import actaAppService from '@/services/acta-apps-service';
import { MessageBox } from 'element-ui';
import i18n from '@/lang';
import { ActaAppDetails } from '@/models/app';

@Component({
  name: 'AppHead',
  components: {
    AppBreadcrumb,
    AppHamburger,
  },
})
export default class extends Vue {
  appsList: ActaAppDetails[] = [];
  currentApp: ActaAppDetails | null = null;


  sectionPopover = false;

  async mounted() {
    await actaAppService.isReady();
    this.currentApp = actaAppService.getCurrentApp() || null;
    this.appsList = Config.APP_LIST;
    if (!this.currentApp && !actaAppService.authenticationError) {
      this.showRequestAccessAlert();
    }
  }


  showRequestAccessAlert() {
    MessageBox.alert(i18n.t('common.no_access').toString(), {
      confirmButtonText: i18n.t('common.request_access').toString(),
      title: i18n.t('common.access_required').toString(),
      type: 'warning',
      showClose: false,
      showCancelButton: false,
      center: true,
      closeOnClickModal: false,
      confirmButtonClass: 'active-button',
      closeOnPressEscape: false,
      beforeClose: () => {
        window.location.href = Config.CONTACT_EMAIL_TO_BIND;
      }
    });
  }

  get sidebar() {
    return AppModule.sidebar;
  }

  get device() {
    return AppModule.device.toString();
  }

  get avatar() {
    return '';
  }

  public toggleSideBar() {
    AppModule.ToggleSideBar(false);
  }

  async logout() {
    const promptResponse = await this.$confirm(i18n.t('logout.message').toString(), i18n.t('logout.title').toString(), {
      confirmButtonText: i18n.t('dialog.common.confirmButtonText').toString(),
      cancelButtonText: i18n.t('dialog.common.cancelButtonText').toString(),
    }).then(() => true).catch(() => false);

    if (promptResponse) {
      window.location.href = process.env.VUE_APP_ARCHIVE_URL + '/logout';
    }
  }

  switchApps(event: any, path: string) {
    const switchApp = document.getElementById('switch-app');
    if (switchApp) {
      switchApp.click();
    }
    window.open(path);
  }
}
</script>

<style lang="scss" scoped>
.el-popover {
  position: fixed;
}

.app-button {
  margin-top: 10px;
  width: 100%;
}

.app-header {
  height: 100px;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  background: #fff;
  position: relative;
}

.topbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    padding: 0 15px;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .navbar {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }


  .el-icon-s-grid {
    cursor: pointer;
    font-size: 18px;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>