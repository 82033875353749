/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'fit_image': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M200-120q-33 0-56.5-23.5T120-200v-480h560v560H200zm0-120h400L464-420 360-280l-64-86-96 126zm0-520h-80q0-33 23.5-56.5T200-840v80zm80 0v-80h80v80h-80zm160 0v-80h80v80h-80zm160 0v-80h80v80h-80zm160 0v-80q33 0 56.5 23.5T840-760h-80zm0 560h80q0 33-23.5 56.5T760-120v-80zm0-80v-80h80v80h-80zm0-160v-80h80v80h-80zm0-160v-80h80v80h-80z"/>'
  }
})
