/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'upload': {
    width: 64,
    height: 64,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M0 128V83h17.25v27.75h93.5V83H128v45H0z"/><g><path pid="1" d="M80.92 210.95v-51.27h18.15L64 113.18l-35.07 46.5h18.15v51.27h33.84z"/><animateTransform attributeName="transform" type="translate" from="0 0" to="0 -220" dur="1200ms" repeatCount="indefinite"/></g>'
  }
})
