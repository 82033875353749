import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { getLanguage } from '@/utils/cookies';

import elementEnLocale from 'element-ui/lib/locale/lang/en';
/* REVIEW:  eslint disabled becuase the name elementSv_SELocale having standardization issue  */
/* eslint-disable-next-line */
import elementSv_SELocale from 'element-ui/lib/locale/lang/sv-SE'

import enLocale from './en';
/* eslint-disable-next-line */
import sv_SELocale from './sv-SE'

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  sv_SE: {
    /* eslint-disable-next-line */
    ...sv_SELocale,
    /* eslint-disable-next-line */
    ...elementSv_SELocale
  },
};

export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }

  const language = navigator.language.toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en'; // Default
};

const i18n = new VueI18n({
  locale: getLocale(),
  messages
});

export default i18n;
