const Endpoints = {
  CREATE_FILE: '/objects/file',
  CREATE_FOLDER: '/objects/folder',
  GET_OBJECT: '/objects',
  OBJECT_RESTORE: '/objects/restore',
  EMPTY_TRASH:'/trash/delete-all',
  PERMANENT_DELETE:'/trash/delete',
  TRASH_RESTORE:'/trash/restore',
  MY_SHARE:'/my-shares',
  UNLINK_SHARE:'/shares/remove',
  SHARE:'/shares',
  RESTORE_CHECK:'/trash/restore/location-check',

} as const;
export default Endpoints;