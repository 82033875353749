/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 17c-.9 0-1.6.3-2.2.9l-8.1-4.8c.1-.3.1-.5.1-.8s0-.5-.1-.8l8-4.7c.6.6 1.4.9 2.3.9 1.9 0 3.4-1.5 3.4-3.4S20.9.9 19 .9s-3.4 1.5-3.4 3.4c0 .3 0 .5.1.8l-8 4.7c-.6-.6-1.4-.9-2.3-.9-1.9 0-3.4 1.5-3.4 3.4s1.5 3.4 3.4 3.4c.9 0 1.7-.4 2.3-.9l8 4.8c-.1.2-.1.5-.1.7 0 1.8 1.5 3.3 3.3 3.3 1.8 0 3.3-1.5 3.3-3.3S20.7 17 18.9 17z"/>'
  }
})
