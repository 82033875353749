
import request from '@/app/request';
import { Config } from '@/app/config';
import { ActaAppDetails } from '@/models/app';

class ActaAppsService {

  getCurrentApp(){
    return  Config.APP_LIST.find((apps) => apps.identification_key === Config.ACTIVE_SECTION_KEY);
  }
  authenticationError = false;
  status:'INIT'|'READY' ='INIT';
  private resolver:Array<Function> =[];

  getConfigurations() {
    this.authenticationError = false;
    return request
      .get(process.env.VUE_APP_ARCHIVE_URL + '/configurations')
      .then(response => {
        return response?.data?.organisation_sections;
      })
      .catch(error => {
        this.authenticationError = error?.response?.status === 401;
        console.error('org fetch error',error);
        return [];
      });
  }
  isReady(){
    return new Promise((resolve)=>{
      this.resolver.push(resolve);
    });
  }

  async init() {
    const organization = await this.getConfigurations();
    Config.APP_LIST = organization as Array<ActaAppDetails>;
    this.status = 'READY';
    this.resolver.forEach(resolverFunction => resolverFunction());
  }
}
export default new ActaAppsService();
