import { debounce } from 'lodash';
import { ObjectDirective } from 'vue';
import { directiveHandlerReference } from '@/utils/common';

const DEFAULT_MIN = 0;
const DEFAULT_MAX = 1000;
const KEYUP_DEBOUNCE_DELAY = 600;
const NUMBER_ONLY_REG_EXP = /\D/g;
const handlerReference = directiveHandlerReference('numberOnly');
export const numberOnly: ObjectDirective = {

    bind(inputElement, binding) {
        const config ={
            min: binding.value.min||DEFAULT_MIN,
            max: binding.value.max||DEFAULT_MAX,
            autoCorrect: !!binding.value.autoCorrect,
        };
        handlerReference.clickHandler = (event: MouseEvent)  =>{
            if( (event.target as HTMLInputElement)?.select){
                (event.target as HTMLInputElement)?.select();
            }
        };
        handlerReference.inputHandler = (event:InputEvent)  =>{
            if((event as any).detail?.custom){
                return;
            }
            const cleanedInput = ((event.target as HTMLInputElement)?.value ||'').replace(NUMBER_ONLY_REG_EXP, '');
            (event.target as HTMLInputElement).value = cleanedInput;
            (event.target as HTMLElement)?.dispatchEvent(new CustomEvent('input',{detail:{custom:true}}));
        };
        handlerReference.keyupDebounceHandler = debounce((event:KeyboardEvent)  =>{
            const value = (event.target as HTMLInputElement).value;
            let parsedInput = parseInt(value,10);
            if(!Number.isInteger(parsedInput)){
                parsedInput = config.min;
            }else if(parsedInput < config.min){
                parsedInput  = config.min;
            }else  if(parsedInput > config.max){
                parsedInput  = config.max;
            }
            (event.target as HTMLInputElement).value = parsedInput+'';
            (event.target as HTMLElement)?.dispatchEvent(new CustomEvent('input',{detail:{custom:true}}));
        },  KEYUP_DEBOUNCE_DELAY, {trailing:true});

        inputElement.addEventListener('click', handlerReference.clickHandler);
        inputElement.addEventListener('input', handlerReference.inputHandler);
        if(config.autoCorrect){
            inputElement.addEventListener('keyup',handlerReference.keyupDebounceHandler );
        }
    },

    unbind(inputElement){
        inputElement.addEventListener('click', handlerReference.clickHandler);
        inputElement.addEventListener('input', handlerReference.inputHandler);
        inputElement.addEventListener('keyup',handlerReference.keyupDebounceHandler );
    }
};
