import Vue from 'vue';
import ElementUI from 'element-ui';
import SvgIcon from 'vue-svgicon';

import 'normalize.css';
import '@/styles/element-variables.scss';
import '@/styles/index.scss';

import ActaMyLib from '@/acta-my-lib.vue';
import store from '@/store';
import { AppModule } from '@/store/modules/app';
import router from '@/app/router';
import i18n from '@/lang';
import '@/icons/components';
import '@/pwa/register-service-worker';
import * as directives from '@/directives';
import * as filters from '@/filters';
import { camelToKebab } from '@/utils/common';

Vue.use(ElementUI, {
  size: AppModule.size,
  i18n: (key: string, value: string) => { i18n.t(key, value); }
});

Vue.use(SvgIcon, {
  tagName: 'svg-icon',
  defaultWidth: '1em',
  defaultHeight: '1em'
});

Object.keys(directives).forEach(key => {
  Vue.directive(camelToKebab(key), (directives as Record<string, any>)[key]);
});

Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as Record<string, any>)[key]);
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(ActaMyLib)
}).$mount('#acta-lib');
