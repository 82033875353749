/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'create_folder': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.5 4.1H12L9.6 1.6h-7C1.3 1.6.2 2.7.2 4.1v15c0 1.4 1.1 2.5 2.4 2.5h18.9c1.3 0 2.4-1.1 2.4-2.5V6.6c0-1.4-1.1-2.5-2.4-2.5zm-1.2 10h-3.6v3.8h-2.4v-3.8h-3.5v-2.5h3.5V7.8h2.4v3.7h3.6V14z"/>'
  }
})
