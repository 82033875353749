import { directiveHandlerReference } from '@/utils/common';

import { ObjectDirective } from 'vue';
/*
This directive prevents unwanted focus, such as focus triggered by dragging,
particularly on plain-style buttons.
*/
const handlerReference = directiveHandlerReference('focusShield');
export const focusShield: ObjectDirective = {
    bind(directiveElement) {
        handlerReference.handler = ()=>{
            directiveElement.blur();
        };
        directiveElement.addEventListener('mouseleave', handlerReference.handler);
    },
    unbind(directiveElement){
        directiveElement.removeEventListener('mouseleave', handlerReference.handler);
    }
};
