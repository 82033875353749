/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'rotate_left': {
    width: 24,
    height: 24,
    viewBox: '0 -960 960 960',
    data: '<path pid="0" d="M440-80q-50-5-96-24.5T256-156l56-58q29 21 61.5 34t66.5 18v82zm80 0v-82q104-15 172-93.5T760-438q0-117-81.5-198.5T480-718h-8l64 64-56 56-160-160 160-160 56 58-62 62h6q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840-438q0 137-91 238.5T520-80zM198-214q-32-42-51.5-88T122-398h82q5 34 18 66.5t34 61.5l-58 56zm-76-264q6-51 25-98t51-86l58 56q-21 29-34 61.5T204-478h-82z"/>'
  }
})
