import { IFolder, IFile } from '../models/file';

class MoveDialogService {
  public dialogRef: any = null;
  public closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  public showDialog(payload: Array<IFile | IFolder>, callback: (id: any) => void) {
    if (this.dialogRef) {
      this.dialogRef.open(payload, callback);
    }
  }
  public getSourceItemType(sourceItems:Array<IFile | IFolder>): 'folder'|'file'|'multiple'|null {
    if(sourceItems.length > 1){
      return 'multiple';
    }else if( sourceItems.length ==0){
      return sourceItems[0].type;
    }else{
      return null;
    }
  }
}
export const moveDialog = new MoveDialogService();
