/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'upload-completed': {
    width: 16,
    height: 16,
    viewBox: '0 0 122.88 74.46',
    data: '<path pid="0" d="M1.87 47.2a6.33 6.33 0 118.92-9c8.88 8.85 17.53 17.66 26.53 26.45l-3.76 4.45-.35.37a6.33 6.33 0 01-8.95 0L1.87 47.2zM30 43.55a6.33 6.33 0 118.82-9.07l25 24.38 47.82-56.57c5.37-6.35 15 1.84 9.66 8.18L69.07 72.22l-.3.33a6.33 6.33 0 01-8.95.12L30 43.55zm28.76-4.21l-.31.33-9.07-8.85 22.29-26.4c5.37-6.35 15 1.83 9.67 8.18l-22.6 26.74z" fill-rule="evenodd"/>'
  }
})
